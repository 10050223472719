import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-user/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`As a supplier, showcase and detail your products for offsite construction.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Creating Products</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Editing Products</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Making a Product Private</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Deleting Products</AnchorLink>
    </AnchorLinks>
    <p>{`If you are a supplier of Products, you can upload your products to the KOPE Marketplace here within the products section of your profile. Once made public, users of the Marketplace will be able to view your products for themselves and this is the first step towards `}<strong parentName="p">{`Products into Projects`}</strong>{`.`}</p>
    <h2>{`Creating Products`}</h2>
    <p>{`There are 2 ways to access product creation, both methods lead to the same product creation functionality.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`On the Landing Page Click the `}<inlineCode parentName="p">{`New Product`}</inlineCode>{` button.
`}<img parentName="p" {...{
            "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/29_creating-products-new-product-button.png",
            "alt": "29_creating-products-new-product-button.png"
          }}></img></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In the Products Tab, Click the `}<inlineCode parentName="p">{`Register Product`}</inlineCode>{` button.
`}<img parentName="p" {...{
            "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/30_add-new-product-button.png",
            "alt": "30_add-new-product-button.png"
          }}></img></p>
      </li>
    </ol>
    <p>{`To add a new product to your profile:`}</p>
    <ol>
      <li parentName="ol">{`Click either of the `}<inlineCode parentName="li">{`New Product Buttons`}</inlineCode>{` shown above.`}</li>
      <li parentName="ol">{`In the opened `}<inlineCode parentName="li">{`Register Product`}</inlineCode>{` window, provide the initial product details:
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/31_products-register-product-pop-up.png",
          "alt": "31_products-register-product-pop-up.png"
        }}></img>
        <pre parentName="li"><code parentName="pre" {...{}}>{`1. \`Product name\` – Display name on the marketplace.
2. \`Building System\` – Select the building system of your product from the drop-down list.
3. \`Product category\` - Select the category of your product from the drop-down list.
4. \`Product Code / SKU (optional)\` – Enter the internal code your organisation may use to refer to this product, e.g. ABC-123. This data is not displayed publicly.
5. \`Describe your product\` – Enter a description for your product.
6. \`Images\` – Upload up to 10 images.
7. \`Show this product on our public marketplace?\` – Toggle off to hide your product from the public marketplace.
`}</code></pre>
      </li>
    </ol>
    <InlineNotification mdxType="InlineNotification">
Please note that you cannot edit the Product category and Product type once you have registered your product, so take your time to ensure they are correct.
    </InlineNotification>
    <ol>
      <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`Register`}</inlineCode>{` button.`}</li>
      <li parentName="ol">{`A `}<inlineCode parentName="li">{`Product registration was successful **`}</inlineCode>{`** pop-up confirms successful registration. To add additional information such as certifications, performance data and BIM content, Click the `}<inlineCode parentName="li">{`Continue Editing`}</inlineCode>{`button.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/32-product-registration-was-successful.png",
          "alt": "32-product-registration-was-successful.png"
        }}></img></li>
    </ol>
    <h2>{`Editing Products`}</h2>
    <p>{`You can access the product editing functionality using two methods.`}</p>
    <ol>
      <li parentName="ol">{`Access through the product creation process:`}
        <ol parentName="li">
          <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`Continue Editing`}</inlineCode>{` button after creating a product (refer to step 4 above).`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Post product creation, from the Products Tab:`}
        <ol parentName="li">
          <li parentName="ol">{`Go to the `}<inlineCode parentName="li">{`Products`}</inlineCode>{` tab.`}</li>
          <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`⫶`}</inlineCode>{` icon next to the product you want to edit.`}</li>
          <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Edit`}</inlineCode>{`.`}</li>
        </ol>
      </li>
    </ol>
    <p>{`Using the toolbar on the left side of the screen, you can navigate to the following pages:`}</p>
    <p><img parentName="p" {...{
        "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/33_attributes-basic-info.png",
        "alt": "33_attributes-basic-info.png"
      }}></img>{` - Basic Info`}</p>
    <p><img parentName="p" {...{
        "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/34_toolbar-attributes.png",
        "alt": "34_toolbar-attributes.png"
      }}></img>{` - Attributes`}</p>
    <p><img parentName="p" {...{
        "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/35_toolbar-documentation.png",
        "alt": "35_toolbar-documentation.png"
      }}></img>{` - Documentation`}</p>
    <p><img parentName="p" {...{
        "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/36_toolbar-bim-content.png",
        "alt": "36_toolbar-bim-content.png"
      }}></img>{` - BIM Content`}</p>
    <h3>{`Basic Info`}</h3>
    <p>{`Basic Info is the first page you will see. This information is the information you provided when creating the product. Here you can make edits to that info and make the product listing Private.
`}<img parentName="p" {...{
        "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/37_product-editing-basic-info.png",
        "alt": "37_product-editing-basic-info.png"
      }}></img></p>
    <h3>{`Attributes`}</h3>
    <p>{`The attributes page allows you to define any relevant specification details or technical values about your product. These values are split into the following tabs:`}</p>
    <ul>
      <li parentName="ul">{`Performance`}</li>
      <li parentName="ul">{`Dimensional`}</li>
      <li parentName="ul">{`Materials`}</li>
      <li parentName="ul">{`Sustainability`}</li>
      <li parentName="ul">{`Classification`}</li>
    </ul>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/38_products-attributes.png",
      "alt": "38_products-attributes.png"
    }}></img>
    <p>{`To edit a specific data value, click the `}<inlineCode parentName="p">{`Click to set data`}</inlineCode>{` button.`}</p>
    <p>{`To change a specific data value, click on the value.`}</p>
    <p>{`To reset a value, click on the value and then click `}<inlineCode parentName="p">{`Reset`}</inlineCode>{`.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/39_edit-value.png",
      "alt": "39_edit-value.png"
    }}></img>
    <p>{`This data is optional. However, the more data you input into your product, the more useful it will be to a specifier.`}</p>
    <h3>{`Documentation`}</h3>
    <p>{`The documentation page allows you to upload any relevant technical, promotional, warranty and other related documents that are associated with your product.`}</p>
    <p>{`KOPE allows for the following file types: .pdf, .dwg, .dxf, .csv. There is a 20 MB maximum file size and a 100 bytes minimum file size.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/40_bim-documentation.png",
      "alt": "40_bim-documentation.png"
    }}></img>
    <p>{`To upload a file:`}</p>
    <ol>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Add file`}</inlineCode>{` in the top right.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/41_product-info-documentation_add-file-button.png",
          "alt": "41_product-info-documentation_add-file-button.png"
        }}></img></li>
      <li parentName="ol">{`The Add file pop-up will appear.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/42_product-info-documentation_add-file.png",
          "alt": "42_product-info-documentation_add-file.png"
        }}></img></li>
      <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`Add File`}</inlineCode>{` button and navigate to the desired document in the file browser.`}</li>
      <li parentName="ol">{`Select the file type from the `}<inlineCode parentName="li">{`File Type`}</inlineCode>{` drop-down.`}</li>
      <li parentName="ol">{`Enter a description of the document.`}</li>
      <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`Add`}</inlineCode>{` button to upload the document to your product.`}</li>
    </ol>
    <p>{`To remove a document, click the `}<inlineCode parentName="p">{`⫶`}</inlineCode>{` icon to the right of the document listing and click `}<inlineCode parentName="p">{`Remove file`}</inlineCode>{`.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/43_removing-documents.png",
      "alt": "43_removing-documents.png"
    }}></img>
    <h3>{`BIM Content`}</h3>
    <p>{`The documentation page allows you to upload any relevant BIM files that are associated with your product.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/44_bim-documentation-copy.png",
      "alt": "44_bim-documentation-copy.png"
    }}></img>
    <p>{`To upload a BIM file:`}</p>
    <ol>
      <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`Add file`}</inlineCode>{` button in the top right.`}</li>
      <li parentName="ol">{`The Add file pop-up will appear.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/45_bim-documentation-add-file.png",
          "alt": "45_bim-documentation-add-file.png"
        }}></img></li>
      <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`Add File`}</inlineCode>{` button and navigate to the desired document in the file browser.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/46_bim-documentation-add-file-button.png",
          "alt": "46_bim-documentation-add-file-button.png"
        }}></img></li>
      <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`Add`}</inlineCode>{`button to add the BIM file to your product.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/47_bim-documentation-add-button.png",
          "alt": "47_bim-documentation-add-button.png"
        }}></img></li>
    </ol>
    <h2>{`Making a Product Private`}</h2>
    <p>{`KOPE allows suppliers to choose for each product whether it should be publicly visible on or hidden from the KOPE Marketplace.`}</p>
    <p>{`To make a product private:`}</p>
    <ol>
      <li parentName="ol">{`Go to the `}<inlineCode parentName="li">{`Product Edit`}</inlineCode>{` page (refer to Editing products for details).`}</li>
      <li parentName="ol">{`Toggle the `}<inlineCode parentName="li">{`Listing Status`}</inlineCode>{` to “Not Listed.”
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/48_products-listing-status-toggle.png",
          "alt": "48_products-listing-status-toggle.png"
        }}></img></li>
      <li parentName="ol">{`Your product is now private.`}</li>
    </ol>
    <h3>{`Updating your Product`}</h3>
    <p>{`Any changes you make to your products after initial creation will not be updated automatically. To update your product with any changes you make, click `}<inlineCode parentName="p">{`Update`}</inlineCode>{` in the top right.
`}<img parentName="p" {...{
        "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/49_product-editing-update-product.png",
        "alt": "49_product-editing-update-product.png"
      }}></img></p>
    <h2>{`Deleting Products`}</h2>
    <p>{`If you would like to delete some products from your profile, please get in touch with us directly and we will be happy to assist.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      